import React,  {useState, useEffect} from "react";

const ControladorEndereco = ({handleEndereco, data, enderecos, validate, validaEndereco, setIsento}) =>{
    const [ NovoEnd, setNovoEnd ] = useState(false)
    const [enderecoFormData, setendFormData] = useState({
          endereco: "",
          numero:"",
          complemento: "",
          cidade:"",   
          cep:"",
          estado:"PR",
      });

      const estados = {
        "AC":"Acre",
        "AL":"Alagoas",
        "AP":"Amapá",
        "AM":"Amazonas",
        "BA":"Bahia",
        "CE":"Ceara",
        "DF":"Distrito Federal",
        "ES":"Espírito Santo",
        "GO":"Goiás",
        "MA":"Maranhão",
        "MT":"Mato Grosso",
        "MS":"Mato Grosso do Sul",
        "MG":"Minas Gerais",
        "PA":"Pará",
        "PB":"Paraíba",
        "PR":"Paraná",
        "PE":"Pernambuco",
        "PI":"Piauí",
        "RJ":"Rio de Janeiro",
        "RN":"Rio Grande do Norte",
        "RS":"Rio Grande do Sul",
        "RO":"Rondônia",
        "RR":"Roraima",
        "SC":"Santa Catarina",
        "SP":"São Paulo",
        "SE":"Sergipe",
        "TO":"Tocantins",
      }

      const setEstado = (e) => {
        setendFormData({
          ...enderecoFormData,
          estado: e.target.value,
        });
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setIsento(false);
        console.log(enderecoFormData)
        setendFormData({
          ...enderecoFormData,
          [name]: value
        });
      
        // Verificar se todos os campos estão preenchidos
        const allFieldsFilled = Object.values(enderecoFormData).every(field => field.trim() !== "");
        if (allFieldsFilled){
            console.log('Completo')
        }
        validaEndereco({
          ...validate,
          endereco:allFieldsFilled
        });
      }
  
      useEffect(()=>{
        handleEndereco({
        ...data,
        endereco:enderecoFormData
        })
      },[enderecoFormData])   
  
      const handleEndSelect = (item) =>{
        handleEndereco({...data, endereco: item}); 
        console.log({...data, endereco: item})
        setNovoEnd(false)
      }
  
    return(
    <div className="p-2">
      <h3 className='text-[13pt] text-slate-800 font-semibold'>
        Endereço de envio
      </h3>
      {enderecos?(
        <div>
          
          {enderecos.length > 0 ? (
            <>
            <p>seus endereços</p>
            <div className="flex flex-col gap-1 max-h-[400px] overflow-scroll no-scrollbar">
            {enderecos.map(item=>(
              <div className="relative flex flex-col w-full p-2 rounded-sm bg-slate-200">
                <p className="m-0 p-0 text-[10pt]">{item.endereco} - {item.numero}</p>
                <p className="m-0 p-0  text-[10pt]">CEP: {item.cep}</p>
                <button className="absolute bottom-2 right-2 p-1 bg-yellow-400 hover:bg-yellow-300 text-[9pt] h-[26px] font-medium px-2" onClick={()=>handleEndSelect(item)}>Selecionar</button>           
              </div>
            ))}
            </div>
            {!NovoEnd ? ( 
              <div className="flex flex-row w-full justify-end mt-4">
                <button className="bg-yellow-400 hover:bg-yellow-300 text-slate-800 font-semibold p-1 rounded-sm justify-end text-[9pt] h-[26px] px-2" onClick={()=>(setNovoEnd(true))}>Cadastrar novo</button>
              </div>
            ) : (
              <div className="flex flex-row w-full justify-end mt-4">
                <button className="bg-yellow-400 hover:bg-yellow-300 text-slate-800 font-semibold p-1 rounded-sm justify-end text-[9pt] h-[26px] px-2" onClick={()=>(setNovoEnd(false))}>Cancelar</button>
              </div>
            ) 
            }
            </>
            
          ) : (
            <div className='flex flex-col gap-2 mt-1'>
                  <div className='flex flex-row gap-1 justify-between'>
                    <input className={`min-w[130px] h-[35px] flex-grow bg-slate-300 rounded flex p-1`}                
                      id="endereco"
                      name="endereco"
                      placeholder="Endereço"
                      value={enderecoFormData.endereco} 
                      onChange={handleChange}  
                      />
                    </div>
                  <div className='flex flex-row flex-wrap gap-1'>
                  <input className={`min-w[80px] flex-grow h-[35px] bg-slate-300 rounded flex p-1`}                 
                      id="numero"
                      type={"number"}
                      name="numero"
                      placeholder="Número"
                      value={enderecoFormData.numero} 
                      onChange={handleChange}  
                      />
                  <input className={`min-w[130px] flex-grow bg-slate-300 rounded flex p-1.5`}                 
                      id="complemento"
                      name="complemento"
                      placeholder="Complemento"
                      value={enderecoFormData.complemento} 
                      onChange={handleChange}  
                      />
                  </div>

                  <div className='flex flex-row flex-wrap gap-1'>
                  <input className={`min-w[130px] flex-grow bg-slate-300 rounded flex p-1.5`}                 
                      id="cidade"
                      name="cidade"
                      placeholder="Cidade"
                      value={enderecoFormData.cidade} 
                      onChange={handleChange}  
                      />
                  <select
                    className={`min-w-[80px] flex-grow bg-slate-300 rounded flex p-1`}
                    id="estado"
                    value={enderecoFormData.estado}
                    placeholder={'Estado'}
                    onChange={setEstado}
                  >
                    {Object.keys(estados).map((sigla) => (
                      <option key={sigla} value={sigla} >
                        {estados[sigla]}
                      </option>
                    ))}
                  </select>
                  </div>
                  <div className='flex flex-col '>
                  <input 
                    className="min-w-[100%] flex-grow bg-slate-300 rounded flex p-1 mt-1"                 
                    id="cep"
                    name="cep"
                    type='text'
                    placeholder="CEP"
                    value={enderecoFormData.cep} 
                    onChange={(e) => {
                      // Remove all non-numeric characters
                      const value = e.target.value.replace(/\D/g, '');
                      // Set the new value if it has a maximum of 8 characters
                      if (value.length <= 8) {
                        handleChange({
                          target: {
                            name: 'cep',
                            value,
                          },
                        });
                      }
                    }}  
                  />
                  </div>
                </div>
          )}
  
        {NovoEnd && (
                  <div className='flex flex-col gap-2 mt-1'>
                  <div className='flex flex-row gap-1 justify-between'>
                    <input className={`min-w[130px] h-[35px] flex-grow bg-slate-300 rounded flex p-1`}                
                      id="endereco"
                      name="endereco"
                      placeholder="Endereço"
                      value={enderecoFormData.endereco} 
                      onChange={handleChange}  
                      />
                    </div>
                  <div className='flex flex-row flex-wrap gap-1'>
                  <input className={`min-w[80px] flex-grow h-[35px] bg-slate-300 rounded flex p-1`}                 
                      id="numero"
                      type={"number"}
                      name="numero"
                      placeholder="Número"
                      value={enderecoFormData.numero} 
                      onChange={handleChange}  
                      />
                  <input className={`min-w[130px] flex-grow bg-slate-300 rounded flex p-1.5`}                 
                      id="complemento"
                      name="complemento"
                      placeholder="Complemento"
                      value={enderecoFormData.complemento} 
                      onChange={handleChange}  
                      />
                  </div>
                  <div className='flex flex-row flex-wrap gap-1'>
                  <input className={`min-w[130px] flex-grow bg-slate-300 rounded flex p-1.5`}                 
                      id="cidade"
                      name="cidade"
                      placeholder="Cidade"
                      value={enderecoFormData.cidade} 
                      onChange={handleChange}  
                      />
                  <select
                    className={`min-w-[80px] flex-grow bg-slate-300 rounded flex p-1 mt-1`}
                    id="estado"
                    value={enderecoFormData.estado}
                    placeholder={'Estado'}
                    onChange={setEstado}
                  >
                    {Object.keys(estados).map((sigla) => (
                      <option key={sigla} value={sigla} >
                        {estados[sigla]}
                      </option>
                    ))}
                  </select>
                  </div>
                  <div className='flex flex-col '>
                  <input 
                    className="min-w-[100%] flex-grow bg-slate-300 rounded flex p-1 mt-1"                 
                    id="cep"
                    name="cep"
                    type='text'
                    placeholder="CEP"
                    value={enderecoFormData.cep} 
                    onChange={(e) => {
                      // Remove all non-numeric characters
                      const value = e.target.value.replace(/\D/g, '');
                      // Set the new value if it has a maximum of 8 characters
                      if (value.length <= 8) {
                        handleChange({
                          target: {
                            name: 'cep',
                            value,
                          },
                        });
                      }
                    }}  
                  />
                  </div>
                </div>  
        )}
  
        </div>
      ):(
          <div className='flex flex-col gap-2 mt-1'>
          <div className='flex flex-row gap-1 justify-between'>
            <input className={`min-w[130px] h-[35px] flex-grow bg-slate-300 rounded flex p-1`}                
              id="endereco"
              name="endereco"
              placeholder="Endereço"
              value={enderecoFormData.endereco} 
              onChange={handleChange}  
              />
            </div>
          <div className='flex flex-row flex-wrap gap-1'>
          <input className={`min-w[80px] flex-grow h-[35px] bg-slate-300 rounded flex p-1`}                 
              id="numero"
              type={"number"}
              name="numero"
              placeholder="Número"
              value={enderecoFormData.numero} 
              onChange={handleChange}  
              />
          <input className={`min-w[130px] flex-grow bg-slate-300 rounded flex p-1.5`}                 
              id="complemento"
              name="complemento"
              placeholder="Complemento"
              value={enderecoFormData.complemento} 
              onChange={handleChange}  
              />
          </div>
          <div className='flex flex-row flex-wrap gap-1'>
                  <input className={`min-w[130px] flex-grow bg-slate-300 rounded flex p-1.5`}                 
                      id="cidade"
                      name="cidade"
                      placeholder="Cidade"
                      value={enderecoFormData.cidade} 
                      onChange={handleChange}  
                      />
                  <select
                    className={`min-w-[80px] flex-grow bg-slate-300 rounded flex p-1`}
                    id="estado"
                    value={enderecoFormData.estado}
                    placeholder={'Estado'}
                    onChange={setEstado}
                  >
                    {Object.keys(estados).map((sigla) => (
                      <option key={sigla} value={sigla} >
                        {estados[sigla]}
                      </option>
                    ))}
                  </select>
          </div>
          <div className='flex flex-col '>
          <input 
            className="min-w-[100%] flex-grow bg-slate-300 rounded flex p-1 mt-1"                 
            id="cep"
            name="cep"
            type='text'
            placeholder="CEP"
            value={enderecoFormData.cep} 
            onChange={(e) => {
              // Remove all non-numeric characters
              const value = e.target.value.replace(/\D/g, '');
              // Set the new value if it has a maximum of 8 characters
              if (value.length <= 8) {
                handleChange({
                  target: {
                    name: 'cep',
                    value,
                  },
                });
              }
            }}  
          />
          </div>
        </div>   
      )}
   
    </div>)
  }
  export default ControladorEndereco;