import React, { useContext, useState } from 'react';
import { EnvContext } from 'Ecommerce';
import { useNavigate } from 'react-router-dom';

export default function Produto({
  id,
  nome,
  slug,
  descricao,
  valor,
  peso,
  altura,
  imagem,
  largura,
  comprimento,
}) {
  const [quantidade, setQuant] = useState(1);
  const produto = {
    'produto_id': id,
    'imagem': imagem,
    'nome': nome,
    'descricao': descricao,
    'valor': valor,
    'quantidade': quantidade,
    'quantity': quantidade,
    'weight': peso,
    'height': altura,
    'width': largura,
    'insurance_value': valor,
    'length': comprimento,
  };

  const { appUrl } = useContext(EnvContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const handleImageLoad = () => {
    setLoading(false);
  };

  // Truncate description to 50 characters and add "..." if necessary
  const truncatedDescription = descricao.length > 100
    ? descricao.substring(0, 100) + '...'
    : descricao;

  return (
    <div className="flex flex-col mt-4 rounded-md shadow-md h-[450px] w-[320px] overflow-hidden">
      <div className="relative w-full overflow-hidden rounded-sm cursor-pointer" style={{ aspectRatio: '1 / 1' }}>
        {loading && (
          <div className="h-full overflow-hidden">
            <div className="min-h-full flex justify-center items-center align-middle bg-slate-300">
              <p className="font-semibold text-slate-700">Loading</p>
            </div>
          </div>
        )}
        <div className="relative w-full h-full">
          <img
            src={`${appUrl}/${imagem}`}
            alt={nome}
            onLoad={handleImageLoad}
            onClick={() => navigate(`/produto/${slug}`)}
            style={{
              display: loading ? 'none' : 'block',
              objectFit: 'cover',
              width: '100%',
              height: '100%',
            }}
            className="absolute"
          />
          <div className='absolute bottom-0 right-0 bg-[#E9CE9F] bg-opacity-80 w-full h-[30px]'>
            <span className="text-secondary mr-auto">
              <p className='text-[#121212] font-bold text-[14pt] text-end'>R${Number(valor).toFixed(2)}</p>
            </span>
          </div>
        </div>
      </div>

      <div className="mt-2 p-1">
        <span className="font-bold text-wrap text-[12pt] h-[40px]">{nome}</span>
      </div>
      <div className="p-1 h-[40px]">
        <span className="font-medium text-[11pt] block overflow-hidden overflow-ellipsis text-wrap">{truncatedDescription}</span>
      </div>
      <div className="flex justify-between w-full mt-auto">
        <button
          className="text-sm p-2 w-full bg-[#E9CE9F] hover:bg-[#efac37] rounded-sm"
          onClick={() => navigate(`/produto/${slug}`)}
        >
          <p className='font-semibold p-0 m-0 text-[12pt]'>Comprar</p>
        </button>
      </div>
    </div>
  );
}