import React, { useState, useContext, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import LoginForm from './LoginForm';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useLogout from 'hooks/useLogout';
import {
  Box,
  IconButton,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Popover // Import Popover
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import axios from "Loja/state/axios";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { CartContext } from "Ecommerce";

import Img from '../../assets/logo-peppechild.png';
const token = localStorage.getItem('token');
const GLOBAL_URL = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles({
  icon: {
    "&:hover": {
      color: "#feb236",
      fontSize: '100px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

// Componente CartMenu
const CartMenu = ({ anchorCartEl, handleCloseCartMenu, cartRef }) => {
  const { cart, rmvCart, newCart, setcartEvent, appUrl } = useContext(CartContext);
  const navigate = useNavigate();
  const total = calcularTotal(cart);

  function calcularTotal(cart) {
    let total = 0;
    if (token) {
      for (const item of cart) {
        if (!isNaN(item.quantidade) && !isNaN(item.valor)) {
          total += (parseFloat(item.valor)) ;
        }
      }
    } else {
      for (const item of cart) {
        if (!isNaN(item.quantidade) && !isNaN(item.valor)) {
          total += (parseFloat(item.valor));
        }
      }
    }
    return total;
  }

  const removeItem = async (id, produto_id) => {
    try {
      await axios.delete(`api/ucartitens/${id}`);
      const novoCart = cart.filter((item) => item.id !== id);
      newCart(novoCart);
      setcartEvent(true);
    } catch (error) {
      console.error(error);
    }
    rmvCart(produto_id);
  };

  return (
    <Popover
      open={Boolean(anchorCartEl)}
      anchorEl={anchorCartEl}
      ref={cartRef}
      onClose={handleCloseCartMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <div className='min-w-[250px] h-[400px] flex flex-col justify-between'>
        <h3 className='font-semibold text-[12pt]'>Seu carrinho</h3>
        <div className='p-1 flex flex-col gap-1 flex-grow overflow-y-scroll'>
          {!token ? (
            <div className='p-2 flex-col gap-2'>
              {cart && cart.length > 0 ? (
                cart.map(item => (
                  <div className='p-1 flex flex-col my-1 bg-slate-200 rounded-md relative' key={item.id}>
                    <div className='flex flex-row gap-1 w-full'>
                      <img src={`${appUrl}/${item.imagem}`} className='w-[60px] h-[60px] rounded-md' alt={item.nome} />
                      <div className='flex-grow flex flex-col'>
                        <p className='font-semibold text-[10pt]'>{item.nome}</p>
                        <p className='-mt-4 text-[10pt]'>R${item.valor}</p>
                      </div>
                    </div>
                    <div className='w-full p-1 flex flex-row justify-end gap-1'>
                      <p className='font-semibold text-[10pt] pt-[10px] m-0'>Quantidade: {item.quantidade}</p>
                    </div>
                    <span className='absolute -top-2 -right-0 bg-red-400 hover:bg-red-300 text-slate-800 shadow-md font-semibold text-[10pt] rounded-full flex justify-center align-middle items-center h-[18px] cursor-pointer' onClick={() => removeItem(item.id, item.produto_id)} aria-label="delete">
                      remover
                    </span>
                  </div>
                ))
              ) : (
                <p className='font-semibold text-[10pt]'>Carrinho vazio</p>
              )}
            </div>
          ) : (
            <div className='p-2 flex-col gap-2'>
              {cart && cart.length > 0 ? (
                cart.map(item => (
                  <div className='p-1 flex flex-col my-1 bg-slate-200 rounded-md relative' key={item.id}>
                    <div className='flex flex-row gap-1 w-full'>
                      <img src={`${appUrl}/${item.imagem}`} className='w-[60px] h-[60px] rounded-md' alt={item.nome} />
                      <div className='flex-grow flex flex-col'>
                        <p className='font-semibold text-[10pt]'>{item.nome}</p>
                        <p className='-mt-4 text-[10pt]'>R${item.valor}</p>
                      </div>
                    </div>
                    <div className='w-full p-1 flex flex-row justify-end gap-1'>
                      <p className='font-semibold text-[10pt] pt-[10px] m-0'>Quantidade: {item.quantidade}</p>
                    </div>
                    <span className='absolute -top-2 -right-0 bg-red-400 hover:bg-red-300 text-slate-800 shadow-md font-semibold text-[10pt] rounded-full flex justify-center align-middle items-center h-[18px] cursor-pointer' onClick={() => removeItem(item.id, item.produto_id)} aria-label="delete">
                      remover
                    </span>
                  </div>
                ))
              ) : (
                <p className='font-semibold text-[10pt]'>Carrinho vazio</p>
              )}
            </div>
          )}
        </div>
        <div className='flex flex-col bg-[#E9CE9F] p-1 justify-center items-center align-bottom'>
          <div className='flex flex-row gap-1 align-middle justify-between w-full h-[50px]'>
            <p className='text-[#121212] text-[10pt]'>
              Subtotal: <br />
              <span className='text-[12pt] font-semibold'>
                R${total.toFixed(2)}
              </span>
            </p>
            <button
              className='bg-[#efac37] hover:bg-[#feac1d] p-1 mt-2 h-[30px] rounded-md'
              onClick={() => navigate('/novaordem')}
            >
              Comprar
            </button>
          </div>
        </div>
      </div>
    </Popover>
  );
};

function NavBar() {
  const isSmScreen = useMediaQuery('(max-width:780px)');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const token = localStorage.getItem('token');
  const { cart } = useContext(CartContext);
  const logout = useLogout();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [anchorCartEl, setAnchorCartEl] = useState(null);
  const [openLogin, setOpenLogin] = useState(false);
  
  // References for detecting clicks outside
  const menuRef = useRef(null);
  const cartRef = useRef(null);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickCart = (event) => {
    setAnchorCartEl(event.currentTarget);
  };

  const handleCloseCartMenu = () => {
    setAnchorCartEl(null);
  };

  const handleClickLogin = () => {
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleCloseNav = (link) => {
    setOpenLogin(false);
    navigate(link);
  };

  const handleLink = (link) => {
    setAnchorEl(null);
    navigate(link);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && anchorEl && !anchorEl.contains(event.target)) {
        handleCloseMenu();
      }
      if (cartRef.current && !cartRef.current.contains(event.target) && anchorCartEl && !anchorCartEl.contains(event.target)) {
        handleCloseCartMenu();
      }
    };
  
    document.addEventListener('click', handleClickOutside); // Change to 'click'
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [anchorEl, anchorCartEl]);

  return (
    <Box className='sticky-top' sx={{ width: "100%", height: "50px", boxSizing: "border-box" }}>
      <Dialog open={openLogin} onClose={handleCloseLogin}>
        <DialogContent>
          <LoginForm onClose={handleCloseNav} />
        </DialogContent>
      </Dialog>

      <Navbar bg="light" expand="md">
        <Drawer anchor="left" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
          <Box sx={{ width: 250 }}>
            <List>
              <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/'); setIsDrawerOpen(false) }}>
                <ListItemText primary="Inicio" />
              </ListItem>
              <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/produtos'); setIsDrawerOpen(false) }}>
                <ListItemText primary="Vestuario" />
              </ListItem>
              <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/sobre'); setIsDrawerOpen(false) }}>
                <ListItemText primary="Sobre" />
              </ListItem>
              <ListItem style={{ cursor: 'pointer' }} onClick={() => { navigate('/contato'); setIsDrawerOpen(false) }}>
                <ListItemText primary="Entre em contato" />
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <div className='w-full p-1 px-4'>
          <div className='flex flex-row justify-between'>
            {isSmScreen && (
              <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            )}
            <img src={Img} width={60} onClick={() => { navigate('/') }} className='cursor-pointer' />
            <div className='flex flex-row justify-center'>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav style={{ width: "100%" }}>
                  <Nav.Link onClick={() => { navigate('/produtos') }}><p className='text-[12pt] font-semibold'>Vestuário</p></Nav.Link>
                  <Nav.Link onClick={() => { navigate('/sobre') }}><p className='text-[12pt] font-semibold'>Sobre</p></Nav.Link>
                  <Nav.Link onClick={() => { navigate('/contato') }}><p className='text-[12pt] font-semibold'>Contato</p></Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </div>
            <div className='flex flex-row gap-1'>
              <div className='text-slate-700 relative hover:text-[#488ff2] border border-1 rounded-md flex flex-row gap-1 align-middle h-[40px] p-2 cursor-pointer' id="cart-button" onClick={handleClickCart} ref={cartRef}>
                <ShoppingCartIcon />
                <p className='text-[10pt] font-semibold '>Carrinho</p>
                {cart && cart.length > 0 && (
                  <span className='absolute -top-2 -right-3 bg-yellow-300 text-slate-900 font-semibold text-[10pt] p-1 rounded-full flex justify-center align-middle w-[24px] h-[24px]'>
                    {cart.length}
                  </span>
                )}
              </div>

              {token ?
                <div className='text-slate-700 hover:text-[#488ff2] border border-1 rounded-md flex flex-row gap-1 align-middle h-[40px] p-2 cursor-pointer' id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClickMenu} ref={menuRef}>
                  <PersonIcon />
                  <p className='text-[10pt] font-semibold '> Conta </p>
                  <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleCloseMenu} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                    <MenuItem onClick={() => { handleLink('/meupainel') }}><Typography>Meu Painel</Typography></MenuItem>
                    <MenuItem onClick={() => { logout() }}>Sair</MenuItem>
                  </Menu>
                </div> :
                <div className='text-slate-700 hover:text-[#488ff2] border border-1 rounded-md flex flex-row gap-1 align-middle h-[40px] p-2 cursor-pointer' id="basic-button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClickLogin}>
                  <PersonIcon />
                  <p className='text-[10pt] font-semibold '> Entrar </p>
                </div>}
            </div>
          </div>
        </div>
      </Navbar>
      <CartMenu anchorCartEl={anchorCartEl} handleCloseCartMenu={handleCloseCartMenu} cart={cart} cartRef={cartRef}/>
    </Box>
  );
}

export default NavBar;
