import React from 'react';
import Img from '../../assets/logo-peppechild.png'
import { useNavigate } from 'react-router-dom';

const Sobre = () => {
  const nav = useNavigate()
  return (
    <div className={'flex flex-col  p-4 sm:py-10 sm:px-20 justify-center align-middle items-center'}>
        <div className='flex max-w-[600px] flex-col border-1 border-yellow-600 rounded-md p-4 shadow-md text-[11pt]'>
        <img src={Img} width={100} height={100} className='m-4'/>
        <p className='px-4'>
            <span className='font-semibold'>Somos uma marca especializada em roupa infantil,</span> localizada em Santa Catarina. <span className='font-semibold'>Nosso compromisso é oferecer roupas de alta qualidade e conforto para crianças</span>, com muito bom gosto em cada detalhe.
        </p>
        <p className='px-4'>
            Em nosso <span className='font-semibold'>e-commerce</span>, trabalhamos com marcas parceiras para oferecer uma <span className='font-semibold'>ampla variedade de produtos</span>. Além disso, <span className='font-semibold'>atuamos como fornecedores</span> para lojas interessadas em revender nossos produtos, garantindo uma parceria de sucesso.
        </p>
        <hr/>
        <p className='px-4'>
        <span className='font-semibold'>Entregamos para todo o Brasil</span>, assegurando que nossos produtos cheguem com rapidez e segurança aos nossos clientes. <span className='font-semibold'>Nossa missão é proporcionar conforto, qualidade e estilo para sua criança</span>, contribuindo para o dia a dia das famílias com produtos que cuidam dos pequenos com carinho.
        </p>
        <hr/>
        <div className='p-2 flex flex-row justify-between'>
          <p className='font-semibold underline cursor-pointer' onClick={()=>(nav('/termos'))}>Termos de uso e cookies</p>
          <p className='font-semibold underline cursor-pointer' onClick={()=>(nav('/politica-devolucao'))}>Política de devoluções</p>
        </div>
        </div>

    </div>
  );
};

export default Sobre;