import { React} from "react";
import { Outlet } from "react-router-dom";
import { Box} from "@mui/material";
import Footer from '../../components/compLoja/Footer';
import Header from '../../components/compLoja/Header';
import NavBar from '../../components/compLoja/NavBar';
import PoliticaAviso from "Loja/components/compLoja/PoliticaAviso";

const Layout = () => {

  return (
    <div className="flex flex-col">
      <NavBar/>
        <div className="flex-grow mt-[20px]">
          <Outlet />
        </div>
      <Footer/>
      <PoliticaAviso/>
    </div>
          
  );
};

export default Layout;