import React, {useState, useEffect} from 'react'
import { Typography, Box, Button,Accordion,AccordionDetails,AccordionSummary, Grid, IconButton, Alert, CircularProgress  } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Form from 'react-bootstrap/Form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {makeStyles} from '@material-ui/core/styles';
import Img from '../../assets/logo-peppechild.png';
import { GenGet, GenPost } from 'Loja/state/api';
import Master from '../../assets/cartao-mastercard.svg';
import Visa from '../../assets/cartao-visa.svg';
import Pix from '../../assets/pix.svg';
import MercadoPago from '../../assets/mercado-pago.svg'

const useStyles = makeStyles({
    botaoFooter: {
      "&:hover": {
        bgcolor:"#08BEFBD",
        color: "white",
      }
    }
  });

function Footer() {
    const [loading, setLoading] = useState(true);
    const [leadmsg, setLeadMsg] = useState(false);
    const [links, setLinks] = useState([]);
    const [email, setEmail] = useState('');


    async function getUser() {
      try {
        const response = await GenGet(`api/params/footer`);
        setLinks(response);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    useEffect(()=>{
      getUser();
    },[])

    const handleRedirect = (url) => {
        var link = url;
        window.location.href = link;
      };


    const HandleLead = async (email) => {
        try {
            var res = await GenPost('api/leads', { email : email});
            const { message } = res;
            setLeadMsg(message);
            setTimeout(() => {
             setLeadMsg(null);
           }, 5000);
          } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                const errorMessage = error.response.data.message;
                setLeadMsg(errorMessage);
              } else {
                setLeadMsg('Erro ao cadastrar Email.');
              }
              setTimeout(() => {
                setLeadMsg(null);
              }, 5000);

          }
      };

  return (
    <Box        
        display="flex"
        flexDirection="column"
        boxSizing="border-box" 
        padding="40px"
        bgcolor="#E9CE9F"
        color='#121212'>
        <Box            
            display="flex"
            flexDirection={{md:"row", xs:"column"}} 
            justifyContent={{md:"space-between", xs:"center"}}
            boxSizing="border-box">
                <Box                    
                    display="flex"
                    flexDirection={{md:"row", xs:"column"}}
                    justifyContent={{md:"left", xs:"center"}}
                    padding='5px'
                    marginLeft='20px'>
                        <Box
                            display='flex'
                            flexDirection='column'>
                            <h4>Receba Atualizações ! </h4>
                            <Typography sx={{display:{md:"block", xs:"none"}}}>Nunca perca as novidades de nossa loja!</Typography>
                        </Box>
                </Box>     
                <Box                    

                    display="flex"
                    flexDirection="row"
                    padding='5px'
                    gap="10px"
                    marginLeft='30px'
                    justifyContent={{md:"left", xs:"center"}}>
                        <Box>
                        <Box                     
                            display="flex"
                            flexDirection={{md:"row", xs:"column"}}
                            gap={1}
                            margin={1}
                            justifyContent={{md:"left", xs:"center"}}>
                        <Form>
                            <Form.Label>Seu Email:</Form.Label>
                            <Form.Control type="email" placeholder="@Email" style={{minWidth:'20vw'}} onChange={(e) => setEmail(e.target.value)}/>
                        </Form>
                        <Button href="#outlined-buttons" 
                        sx={{ 
                            height:'40px', 
                            top:{md:'30px',xs:'0px'}, 
                            minWidth:'130px',
                            "&:hover": {
                                bgcolor:"#feac1d",
                                color: "#121212"},
                            bgcolor:'#efac37', 
                            color:"#121212"}} onClick={()=>{HandleLead(email)}}>
                            SE INSCREVA
                        </Button>
                        </Box>
                        {leadmsg ? 
                            <Box display={'flex'} flexDirection={'row-reverse'} padding={1}>
                            <Alert severity="success" sx={{width:320}} > {leadmsg} </Alert>
                            </Box>
                        : null}

                        
                        </Box>
                </Box>
        </Box>
        <hr/>
        <div className='flex justify-between flex-col md:flex-row'>
            <div className='flex flex-col p-4 flex-grow min-w-[50%]'>
                <img src={Img} width={100} height={100}/>
                <br/>
                    <p className={'px-2 py-0 m-0 text-[10pt]'}><span className='font-semibold'>Email:</span>atendimento@peppechild.com.br</p>
                    <p className={'px-2 py-0 m-0 text-[10pt]'}><span className='font-semibold'>Tel/WhatsApp:</span> (47) 99117-7022</p>
            </div>

            <div className='flex flex-col p-4 min-w-[50%] flex-grow'>
            <p className={'px-2 py-0 m-0 font-semibold'}>Nossas redes sociais:</p>
            <div className='flex flex-row px-2 gap-1'>
                <IconButton aria-label="Facebook" onClick={() =>handleRedirect(links.facebook)}>
                <FacebookIcon style={{color:'blue'}}/>
                </IconButton>
                <IconButton aria-label="WhatsApp" onClick={() => handleRedirect(links.whatsapp)}>
                <WhatsAppIcon style={{color:'green'}}/>
                </IconButton>
                <IconButton aria-label="Instagram" onClick={() => handleRedirect(links.instagram)}>
                <InstagramIcon style={{color:'red'}}/>
                </IconButton>
            </div>
            <br/>
            <p className={'px-2 py-0 m-0 font-semibold'}>Formas de pagamento:</p>
            <div className='flex flex-row px-2 gap-1'>
                <img src={Visa}/>
                <img src={Master}/>
                <img src={Pix}/>
                <img src={MercadoPago} className='w-[70px]'/>
            </div>
            </div>

        </div>
    </Box>
  )
}

export default Footer