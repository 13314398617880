import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Typography, TextField, Grid, CircularProgress, Alert } from '@mui/material';
import { GenGet, GenPost } from 'Loja/state/api';
import { CartContext } from 'Ecommerce';
import ImageSlider from 'Loja/components/compLoja/ImageSlider';
import ProdutosRelacionados from 'Loja/components/compLoja/ProdutosRelacionados';
import { handleArticle } from 'Loja/components/compLoja/ArtigoProdutos';

const ProdutoDet2 = () => {
  const location = useLocation();
  const slug = useParams().slug.toString();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [produto, setProduto] = useState({});
  const { cart, addCart, setCartIten, setcartEvent } = useContext(CartContext);
  const [quantidade, setQuantidade] = useState(1);
  const [qtdLimit, setQtdLimit] = useState(null);
  const [estoque, setEstoque] = useState([]);
  const [variante, setVariante] = useState(null);
  const [valMsg, setValMsg] = useState(null);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    const getProdutos = async () => {
      setIsLoading(true);
      try {
        const response = await GenGet(`api/uprodutos/${slug}`);
        setData(response);
        setProduto({
          produto_id: response.id,
          imagem: response.imagens[0]?.imagem,
          nome: response.nome,
          descricao: response.descricao,
          valor: response.valor,
          quantidade:quantidade,
          quantity: quantidade,
          weight: response.peso,
          height: response.altura,
          width: response.argura,
          insurance_value: response.valor,
          length: response.comprimento
        });
        setEstoque(response.estoque.unidade_estoque);
        setIsLoading(false);
        if (response.estoque_composto === 0){
          setQtdLimit(response.estoque.quantidade)
        }
      } catch (err) {
        setNotFound(true);
        setIsLoading(false);
      }
    };

    getProdutos();
  }, [location, slug]);

  const handleQuantidadeChange = (e) => {
    let value = parseInt(e.target.value);
    if (isNaN(value) || value < 1) {
      value = 1;
    } else if (qtdLimit !== null && value > qtdLimit) {
      value = qtdLimit;
    }
    setQuantidade(value);
  };

  useEffect(() => {
    setProduto((prevProduto) => ({
      ...prevProduto,
      quantidade: Number(quantidade),
      quantity: Number(quantidade)
    }));
  }, [quantidade]);

  useEffect(() => {
    if (variante) {
      setProduto((prevProduto) => ({
        ...prevProduto,
        estoque_unidade_id: variante.estoque_unidade_id,
      }));
    }
  }, [variante]);

  const adicionar = (produto) => {
    if (token) {
      handleSubmit(produto);

    } else if (data.estoque_composto === 1) {
      if( !variante || !variante.estoque_unidade_id ) {
        setValMsg('Escolha a opção do produto!')
        setTimeout(() => {
          setValMsg(null);
        }, 3000);
        return;
      }

      const itemIndex = cart.findIndex((item) => item.produto_id === produto.produto_id);
      if (itemIndex === -1) {
        addCart({ ...produto, quantidade: Number(produto.quantidade), estoque_unidade_id: Number(produto.estoque_unidade_id) });
      } else {
        setCartIten({ ...produto, quantidade: Number(produto.quantidade), estoque_unidade_id: Number(produto.estoque_unidade_id) });
      }

    } else{

      const itemIndex = cart.findIndex((item) => item.produto_id === produto.produto_id);
      if (itemIndex === -1) {
        addCart({ ...produto, quantidade: Number(produto.quantidade), estoque_unidade_id: null });
      } else {
        setCartIten({ ...produto, quantidade: Number(produto.quantidade), estoque_unidade_id: null });
      }
    }
  };

  const handleVarianteChange = (e) => {
    const selectedId = parseInt(e.target.value);
    setQuantidade(1);
    setVariante({ estoque_unidade_id: selectedId });
    const unidade = estoque.find((item) => item.id === selectedId);
    setQtdLimit(unidade ? unidade.quantidade : 0);
  };

  const handleSubmit = async (produto) => {
    if (data.estoque_composto === 1) {
      if (!variante || !variante.estoque_unidade_id) {
        setValMsg('Escolha a opção do produto!');
        setTimeout(() => {
          setValMsg(null);
        }, 3000);
        return;
      }
    }

    const item = {
      produto_id: produto.produto_id,
      quantidade: parseInt(produto.quantidade),
      estoque_unidade_id: produto.estoque_unidade_id ? parseInt(produto.estoque_unidade_id) : null
    };
    try {
      await GenPost("api/ucartitens", item);
      setcartEvent(true);
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', width: '100%', height: 400, justifyContent: 'center' }}>
        <CircularProgress color="success" sx={{ marginTop: '150px' }} />
      </Box>
    );
  } else if (notFound) {
    return navigate('/');
  } else {
    return (
      <Grid container justifyContent="center" className='h-[100%] mt-10'>
        <Grid item xs={12} md={4}>
          {/* esquerda */}
          <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" height="100%">
            <Box margin={1} maxWidth="500px" overflow="hidden" className="relative flex flex-row p-2">
              {data.imagens ? (
                <ImageSlider images={data.imagens} />
              ) : null}
            </Box>
          </Box>
        </Grid>
        {/* direita */}
        <Grid marginBottom={2} item xs={12} md={6} margin={1} justifyContent={'space-between'} className={'rounded-md shadow-md'} sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'Gainsboro' }} flexDirection={"column"} maxHeight={"75vh"} minHeight={"70vh"}>
          <div className='flex text-left w-[100%] px-2 pt-1'>
            <h3 className='font-semibold text-[14pt]'>{data.nome}</h3>
          </div>
          <div className='w-[100%] overflow-scroll no-scrollbar flex flex-col p-2'>
            <div className='w-[100%] flex flex-col bg-white rounded-md p-1'>
              <div className='flex flex-col'>
                <p className='font-semibold text-[11pt] p-2'>{data.descricao}</p>
                {data.estoque_composto !== 1 && data.exibir_estoque  && (
                <>                    
                {qtdLimit >= 1 &&(<p className='text-[9pt] p-2 -mt-9'>quantidade em estoque: {qtdLimit}</p>)}</>
                )}
              </div>
            </div>
            {data.estoque_composto === 1 && (
              <div className='flex flex-col gap-2 w-full'>
                <div className='flex flex-col gap-2 w-full justify-end'>
                  <p className='font-semibold text-[11pt] p-1'>Opções: </p>
                  <div className='flex flex-row h-[40px]'>
                    <select className='p-1 rounded-md min-w-[120px] h-[30px]' onChange={handleVarianteChange}>
                      <option value={null}>Selecione</option>
                      {estoque && estoque.map((unidade) => (
                        unidade.quantidade > 0 ? (<option key={unidade.id} value={unidade.id}>{unidade.nome}</option>) : null
                      ))}
                    </select>
                  </div>
                  {qtdLimit >= 1 && data.exibir_estoque &&  (<p className='text-[9pt] -mt-4'>quantidade em estoque: {qtdLimit}</p>)}
                  {valMsg ? 
                    <div className={"p-1 h-40px"} >
                      <Alert severity="info"> {valMsg} </Alert>
                    </div>
                : null}
                </div>
              </div>
            )}
            <div className='w-[100%] min-h-[40vh] flex flex-col rounded-md p-1 gap-4'>
              <div className='flex'>
                <h3 className='font-semibold text-[14pt]'>Mais detalhes</h3>
              </div>
              {Object.values(data.artigos).map((item, index) => (
                <div key={index}>{handleArticle(item)}</div>
              ))}
            </div>
          </div>
          <div className='flex flex-row bg-gray-700 text-slate-100 rounded-b-lg sticky bottom-0 w-[100%]'>
            <div className='flex flex-col w-[50%] p-2'>
              <p className='font-semibold text-[11pt] p-0 m-0'><b>Categoria:</b> {data.categoria_nome}</p>
              <p className='font-semibold text-[11pt] p-0 m-0'><b>Valor:</b> <span className='text-[14pt]'>R$ {data.valor}</span> </p>
            </div>
            <div className='w-[50%] justify-end align-middle items-center flex flex-row gap-1'>
              <TextField
                size='small'
                sx={{ width: '70px', backgroundColor: 'white', borderRadius: '5px', padding: '5px', maxHeight: '40px' }}
                variant='standard'
                id="outlined-number"
                type="number"
                value={quantidade}
                onChange={handleQuantidadeChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className='p-1 bg-yellow-400 text-slate-900 rounded-md justify-center align-middle items-center flex w-[120px] font-bold h-[40px] mr-1 cursor-pointer hover:bg-yellow-300 shadow-md' onClick={() => adicionar(produto)}>Adicionar</div>
            </div>
          </div>
        </Grid>
        <div className='flex justify-start align-middle text-left w-[100vw]'>
          <ProdutosRelacionados produto_id={data.id} />
        </div>
      </Grid>
    );
  }
};

export default ProdutoDet2;
