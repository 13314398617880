import React, { useState, useEffect } from 'react';
import { Box, IconButton, CircularProgress } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const GLOBAL_URL = process.env.REACT_APP_BASE_URL;

const ImageSlider = ({ images }) => {
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [magnifyStyle, setMagnifyStyle] = useState({});
  const [isMagnifyVisible, setIsMagnifyVisible] = useState(false);

  useEffect(() => {
    if (images && images.length > 0) {
      setMagnifyStyle({ backgroundImage: `url(${GLOBAL_URL}/${images[0].imagem})` });
    }
  }, [images]);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const nextImage = () => {
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setActiveImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const selectImage = (index) => {
    setActiveImageIndex(index);
  };

  useEffect(() => {
    const capaIndex = images.findIndex(image => image.capa);
    setActiveImageIndex(capaIndex !== -1 ? capaIndex : 0);
  }, [images]);

  useEffect(() => {
    if (activeImageIndex < 0) {
      setActiveImageIndex(images.length - 1);
    } else if (activeImageIndex >= images.length) {
      setActiveImageIndex(0);
    }
  }, [activeImageIndex, images]);

  const handleMouseMove = (e) => {
    const { offsetX, offsetY, target } = e.nativeEvent;
    const { offsetWidth, offsetHeight } = target;

    const xPercentage = (offsetX / offsetWidth) * 100;
    const yPercentage = (offsetY / offsetHeight) * 100;

    setMagnifyStyle((prev) => ({ 
      ...prev, 
      backgroundPosition: `${xPercentage}% ${yPercentage}%`,
      backgroundImage: `url(${GLOBAL_URL}/${images[activeImageIndex].imagem})`
    }));
  };

  const handleMouseEnter = () => {
    setIsMagnifyVisible(true);
  };

  const handleMouseLeave = () => {
    setIsMagnifyVisible(false);
  };

  if (!images || images.length === 0) {
    return <CircularProgress color="success" sx={{ marginTop: '150px' }} />;
  }

  return (
    <div className='h-[100%]'>
      <div className="flex flex-col items-center w-[100%]">
        <div className="relative flex justify-center items-center w-full min-h-full max-h-[450px] overflow-hidden rounded-md">
        <div className='absolute top-50 left-0 z-2'>
        <IconButton 
              onClick={prevImage}
              style={{ backgroundColor: 'transparent', color: 'grey', zIndex: 1 }}
            >
              <ArrowBackIosIcon />
            </IconButton>
        </div>
          <img
            className='shadow-lg'
            src={`${GLOBAL_URL}/${images[activeImageIndex].imagem}`}
            width="100%"
            style={{ display: loading ? 'none' : 'block' }}
            onLoad={handleImageLoad}
            alt={`Image ${activeImageIndex + 1}`}
            onMouseMove={handleMouseMove}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          {isMagnifyVisible && (
            <div 
              className='magnify' 
              style={{
                ...magnifyStyle, 
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
                backgroundSize: '200%',
                pointerEvents: 'none'
              }}
            ></div>
          )}
        <div className='absolute top-50 right-0'>
          <IconButton
              onClick={nextImage}
              style={{ backgroundColor: 'transparent', color: 'grey', zIndex: 1 }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
        </div>

        </div>

        <div className="w-full flex flex-row flex-wrap justify-start mt-1 shadow-md overflow-x-scroll no-scrollbar">
          {images.map((image, index) => (
            <img
              className="h-[80px] m-1"
              key={index}
              src={`${GLOBAL_URL}/${image.imagem}`}
              alt={`Thumbnail ${index + 1}`}
              style={{ cursor: 'pointer' }}
              onClick={() => selectImage(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
