import React from 'react'

export const handleArticle = (dataset) => {
    function handleCopy(data) {
      return (
        <div className='bg-white rounded-md p-1 shadow-md'>
          <p className='font-medium text-[11pt] px-2'>{data.conteudo}</p>
        </div>
      );
    }
  
    function handleEspecs(data) {
      const formatItens = data.itens.replace(/\[|\]|'/g, '');
      const itensArray = formatItens.split(', ');
      return (
        <div className='bg-white rounded-md p-1 shadow-md'>
          <p className='font-semibold text-[12pt] px-2'>{data.conteudo}</p>
          <div className='font-medium text-[11pt] px-2'>
            {data.itens
              ? itensArray.map((item, index) => <p className='p-0 m-0' key={index}> - {item}</p>)
              : <></>}
          </div>
        </div>
      );
    }
  
    function handleText(data) {
      return (
        <div className='bg-white rounded-md p-1 shadow-md'>
          <h4 className='font-semibold text-[12pt]'>{data.titulo}</h4>
          <p className='font-medium text-[10pt] p-2'>{data.conteudo}</p>
        </div>
      );
    }
  
    const functionsMap = {
      'descrição para venda': handleCopy,
      'especificações': handleEspecs,
      'texto livre': handleText,
    };
  
    return functionsMap[dataset.tipo](dataset);
  };