import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Box, Typography , Stack} from "@mui/material";
import FreteRapido from '../../assets/frete.png';
import FreteBrasil from '../../assets/brasil.png';
import Pagamento from '../../assets/pagamento.png';
import Atendimento from '../../assets/atendimento.png';

const useStyles = makeStyles({
    icon: {
      "&:hover": {
        width:'60px'
      }
    }
  });

function Selos() {
    const classes = useStyles();
  return (
    <Stack direction="row" spacing={2} justifyContent="center" mt={4}>
      <div className='h-[100px] rounded-md text-center flex flex-col justify-center align-middle items-center'>
      <p className='text-yellow-600 font-semibold text-[11pt]'>Envio rápido</p>
      <img src={FreteRapido} width='40px' className={classes.icon} alt={'Entrega Logo'}/>
      
        
      </div>
      <div className='h-[100px] rounded-md text-center flex flex-col justify-center align-middle items-center'>
      <p className='text-yellow-600 font-semibold text-[11pt] text-wrap'>Frete para todo o Brasil</p>
      <img src={FreteBrasil} width='40px' className={classes.icon} alt={'Ampla Entrega Logo'}/>

      </div>
      <div className='h-[100px] rounded-md text-center flex flex-col justify-center align-middle items-center'>
      <p className='text-yellow-600 font-semibold text-[11pt]'>Pagamento seguro</p>
      <img src={Pagamento} width='40px' className={classes.icon} alt={'Pagamento Seguro Logo'}/>

      </div>
      <div className='h-[100px] rounded-md text-center flex flex-col justify-center align-middle items-center'>
      <p className='text-yellow-600 font-semibold text-[11pt]'>Excelente atendimento</p>
      <img src={Atendimento} width='40px' className={classes.icon} alt={'Atendimento Logo'}/>

      </div>
    </Stack>
  )
}

export default Selos