import React, {useState, useEffect} from 'react'
import { GenGet } from 'Loja/state/api';
import { Box, CircularProgress } from '@mui/material';
import Carousel from 'react-bootstrap/Carousel';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, ThemeProvider, createTheme } from '@mui/material';

const GLOBAL_URL = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles({
  carrocel: {
    maxHeight: '60vh',
  },
  img: {
    width:"100%",
    margin: 'auto',
    display: 'block',
  }
});

function Carrocel() {
  const [images, setImages] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  async function getCarrocel() {
    try {
        const response = await GenGet('api/carrocel/index');
        console.log(response)
        setImages(response);
        setIsLoading(false);
    } catch (err) {
        //
    }
  }
  useEffect(() => {
    getCarrocel();
  }, [])  

  const classes = useStyles();

  return (<>
          {isLoading?(
            <div className='p-1 w-full h-[70vh] flex justify-center items-center'>
                <CircularProgress color="success" />
            </div>
        ):(
          <Box>
            <Carousel variant="light" className={classes.carrocel}>
              {images && images.map((item, index) => (
                <Carousel.Item key={index} className={classes.carrocel}>
                  <img className={classes.img} src={`${GLOBAL_URL}/${item.path}`} alt={`Slide ${index + 1}`} />
                </Carousel.Item>
              ))}
            </Carousel>
          </Box>)}
  </>
    
  );
}

export default Carrocel;