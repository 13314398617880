import React, { useContext, useState } from 'react';
import { EnvContext } from 'Ecommerce';
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function Destaque({ slug, nome, imagem, descricao }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { appUrl } = useContext(EnvContext);

  const handleImageLoad = () => {
    setLoading(false);
  };

  // Garantir que descricao tenha um valor padrão se estiver indefinido
  const truncatedDescription = (descricao || '').length > 50
    ? (descricao || '').substring(0, 50) + '...'
    : descricao || '';

  return (
    <div className="cursor-pointer flex flex-col min-h-[280px] max-h-[400px] min-w-[260px] max-w-[260px] overflow-hidden shadow-lg" onClick={() => navigate(`/produto/${slug}`)}>
      <div className="relative w-full overflow-hidden cursor-pointer" style={{ paddingTop: '100%' }}>
        {loading && (
          <div className="absolute inset-0 flex justify-center items-center bg-slate-300">
            <CircularProgress />
          </div>
        )}
        <img
          src={`${appUrl}/${imagem}`}
          alt="Product"
          className="absolute inset-0 object-cover w-full h-full"
          style={{
            display: loading ? 'none' : 'block',
          }}
          onLoad={handleImageLoad}
        />
      </div>

      <div className="w-full flex flex-col justify-between items-center mt-2 p-2">
        <h3 className="font-semibold text-lg text-[12pt] text-center truncate w-full" style={{ maxHeight: '3rem' }}>{nome}</h3>
        <p className="font-medium text-sm text-[10pt] text-center truncate w-full" style={{ maxHeight: '3rem' }}>{truncatedDescription}</p>
      </div>
    </div>
  );
};
